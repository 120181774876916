@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;500;600;700;800&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  font-family: "Open Sans", sans-serif;
}

.hero-pattern {
  background: linear-gradient(to bottom, rgba(89, 180, 224, 0.1), rgba(89, 180, 224, 0.2)), url('./assets/images/hero.jpg') no-repeat;
  background-size: cover;

}

.webkit-text-fill-white {
  -webkit-text-fill-color: #ffffff !important;
}