/* styles.css or a similar CSS file */
.job-postings-grid {
    display: grid;
    gap: 16px;
    grid-template-columns: repeat(1, 1fr);
  }
  
  @media (min-width: 640px) {
    .job-postings-grid {
      grid-template-columns: repeat(2, 1fr);
    }
  }
  
  @media (min-width: 1024px) {
    .job-postings-grid {
      grid-template-columns: repeat(4, 1fr);
    }
  }
  
  .job-posting {
    /* Add any additional styles for individual job postings */
  }
  